import axios from "axios";
import config from "./config";

var md5 = require("md5");

async function refreshBusiness(body) {
	try {
		let url = "/user/refreshBusiness";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getDetail(business_id) {
	try {
		let response = await axios.get("/business/detail");
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getServices(business_id) {
	try {
		let url = "/business/service/getAll";
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getEmployeeService(service_id) {
	try {
		let url = "/business/service/employee?service_id=" + service_id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createBusinessService(formData) {
	try {
		let url = "/business/service/create";
		let response = await axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateBusinessService(formData) {
	try {
		let url = "/business/service/update";
		let response = await axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function update(body) {
	try {
		let url = "/business/update";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function withDraw(body) {
	try {
		let url = "/business/withDraw";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function withDrawHistory(fromTime, toTime) {
	try {
		let body = {};
		if (fromTime != "") {
			body.from = fromTime;
		}
		if (toTime != "") {
			body.to = toTime;
		}
		let url = "/business/withDrawHistory";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function checkOutHistory(
	fromTime,
	toTime,
	pageSize,
	pageIndex,
	paymentType
) {
	try {
		let body = {
			pageSize: pageSize,
			pageIndex: pageIndex,
			payment_type: paymentType,
		};
		if (fromTime != "") {
			body.from = fromTime;
		}
		if (toTime != "") {
			body.to = toTime;
		}
		let url = "/business/checkOutHistory";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createBusiness(body) {
	try {
		let url = "/business/create";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getNordigenBanks() {
	try {
		let url = "/business/accountant/getAllBanks";
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function agreements(id) {
	try {
		let url = "/business/accountant/agreements/" + id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function transactions(id) {
	try {
		let url = "/business/accountant/transactions/" + id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function bankBalances(id) {
	try {
		let url = "/business/accountant/balances/" + id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createAgreement(body) {
	try {
		let url = "/business/accountant/create";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getLastStatement(body) {
	try {
		let url = "/business/accountant/getLastStatement";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateAgreement(body) {
	try {
		let url = "/business/accountant/update";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllAgreement(body) {
	try {
		let url = "/business/accountant/getAll";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function checkRequisition(body) {
	try {
		let url = "/business/accountant/checkRequisition";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function deleteRequisition(id, body) {
	try {
		let url = "/business/accountant/delete/" + id;
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllCheckin(body) {
	try {
		let url = "/business/checkin/getAll";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createServiceCatalogue(body) {
	try {
		let url = "/business/service/createCatalogue";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateServiceCatalogue(body) {
	try {
		let url = "/business/service/updateCatalogue";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllServiceCatalogue() {
	try {
		let url = "/business/service/getAllCatalogue/";
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllTransactions(body) {
	try {
		let url = "/business/accountant/getAllTransaction/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllJournal(body) {
	try {
		let url = "/business/accountant/getAllJournal/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}
async function createTransaction(formData) {
	try {
		let url = "/business/accountant/createTransaction";
		let response = await axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateTransaction(formData) {
	try {
		let url = "/business/accountant/updateTransaction";
		let response = await axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function deleteTransaction(item) {
	try {
		let url = "/business/accountant/deleteTransaction/";
		let response = await axios.post(url, item);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createJournal(formData) {
	try {
		let url = "/business/accountant/createJournal";
		let response = await axios.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function deleteJournal(item) {
	try {
		let url = "/business/accountant/deleteJournal/";
		let response = await axios.post(url, item);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getOfficialCompanyInfo(searchText, pageSize, pageIndex) {
	try {
		let body = {
			searchText: searchText,
			pageSize: pageSize,
			pageIndex: pageIndex,
		};
		let url = "/business/getBusinessOfficialInfo/";
		let response = await axios.post(url, body);

		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getBusinessOfficialDetail(company_number) {
	try {
		let body = {
			company_number: company_number,
		};
		let url = "/business/getBusinessOfficialDetail/";
		let response = await axios.post(url, body);

		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllCustomBank(body) {
	try {
		let url = "/business/accountant/getAllCustomBank/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createCustomBank(body) {
	try {
		let url = "/business/accountant/createCustomBank/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateCustomBank(body) {
	try {
		let url = "/business/accountant/updateCustomBank/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function deleteCustomBank(body) {
	try {
		let url = "/business/accountant/deleteCustomBank/";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

export default {
	getDetail: getDetail,
	update: update,
	getServices: getServices,
	updateBusinessService: updateBusinessService,
	createBusinessService: createBusinessService,
	getEmployeeService: getEmployeeService,
	withDraw: withDraw,
	withDrawHistory: withDrawHistory,
	checkOutHistory: checkOutHistory,
	createBusiness: createBusiness,
	getNordigenBanks: getNordigenBanks,
	agreements: agreements,
	transactions: transactions,
	createAgreement: createAgreement,
	updateAgreement: updateAgreement,
	getAllAgreement: getAllAgreement,
	checkRequisition: checkRequisition,
	deleteRequisition: deleteRequisition,
	getAllCheckin: getAllCheckin,
	bankBalances: bankBalances,
	createServiceCatalogue: createServiceCatalogue,
	updateServiceCatalogue: updateServiceCatalogue,
	getAllServiceCatalogue: getAllServiceCatalogue,
	getLastStatement: getLastStatement,
	getAllTransactions: getAllTransactions,
	createTransaction: createTransaction,
	updateTransaction: updateTransaction,
	deleteTransaction: deleteTransaction,
	getOfficialCompanyInfo: getOfficialCompanyInfo,
	getBusinessOfficialDetail: getBusinessOfficialDetail,
	getAllCustomBank: getAllCustomBank,
	updateCustomBank: updateCustomBank,
	createCustomBank: createCustomBank,
	deleteCustomBankm: deleteCustomBank,
	getAllJournal: getAllJournal,
	createJournal: createJournal,
	deleteJournal: deleteJournal,
	refreshBusiness: refreshBusiness,
};
