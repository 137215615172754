import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Shop from "../views/Shop/index.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const Task = lazy(() => import("../views/task"));
const DoingTask = lazy(() => import("../views/task/doing"));
const CompleteTask = lazy(() => import("../views/task/done"));
const Order = lazy(() => import("../views/order"));
const OrderDetail = lazy(() => import("../views/order/detail"));
const CheckOut = lazy(() => import("../views/checkout"));
const StripeCheckout = lazy(() => import("../views/checkout/stripecheckout"));

const QRCode = lazy(() => import("../views/qrcode"));

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));
const PaymentHistory = lazy(() => import("../views/history/payment"));

/*****Routes******/

const ThemeRoutes = [
	{ path: "*", exact: true, element: <Navigate to={"/"} /> },
	{
		path: "/shop",
		element: <Shop />,
	},
	{ path: "/checkout", exact: true, element: <CheckOut /> },
	{ path: "/stripecheckout", exact: true, element: <StripeCheckout /> },
	{ path: "/qrcode", exact: true, element: <QRCode /> },
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="task" />,
			},
			{ path: "/task", exact: true, element: <Task /> },
			{ path: "/doing", exact: true, element: <DoingTask /> },
			{ path: "/payment", exact: true, element: <PaymentHistory /> },
			{ path: "/done", exact: true, element: <CompleteTask /> },
			{ path: "/order", exact: true, element: <Order /> },
			{ path: "/order/:id", exact: true, element: <OrderDetail /> },
		],
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/change-password",
		element: <ChangePassword />,
	},
];

export default ThemeRoutes;
