import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Shop from "../views/Shop/index.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/
const Withdraw = lazy(() => import("../views/admin/withdraw"));
const Checkout = lazy(() => import("../views/admin/checkout"));

const ManageShop = lazy(() => import("../views/admin/business"));
const ShopDetail = lazy(() => import("../views/admin/businessDetail"));
const ShopAgencyDetail = lazy(() =>
	import("../views/admin/agencyBusinessDetail")
);
const AgencyDashboard = lazy(() => import("../views/admin/agencyDashboard"));
const Review = lazy(() => import("../views/checkin/review"));

const CreateShop = lazy(() => import("../views/admin/create"));
const Dashboard = lazy(() => import("../views/admin/dashboard"));
const ListAccountant = lazy(() => import("../views/admin/listAccountant"));
const AccountantDetail = lazy(() => import("../views/admin/accountantDetail"));

const Login = lazy(() => import("../views/Login"));
const ChangePassword = lazy(() => import("../views/ChangePassword"));

/*****Routes******/

function getRoutes(user) {
	if (user.role == "accountant")
		return [
			{ path: "*", exact: true, element: <Navigate to={"/"} /> },
			{
				path: "/",
				element: <FullLayout />,
				children: [
					{
						path: "/",
						element: <Navigate to="listaccountant" />,
					},
					{
						path: "/createshop",
						exact: true,
						element: <CreateShop />,
					},
					{
						path: "/listaccountant",
						exact: true,
						element: <ListAccountant />,
					},
					{
						path: "/accountantDetail/:id",
						exact: true,
						element: <AccountantDetail />,
					},
				],
			},
			{
				path: "/login",
				element: <Login />,
			},
			{
				path: "/change-password",
				element: <ChangePassword />,
			},
		];
	if (user.role == "agency")
		return [
			{ path: "*", exact: true, element: <Navigate to={"/"} /> },
			{ path: "/review", exact: true, element: <Review /> },
			{
				path: "/",
				element: <FullLayout />,

				children: [
					{
						path: "/",
						element: <Navigate to="dashboard" />,
					},
					{
						path: "/createshop",
						exact: true,
						element: <CreateShop />,
					},
					{
						path: "/dashboard",
						exact: true,
						element: <AgencyDashboard />,
					},

					{
						path: "/manageshop",
						exact: true,
						element: <ManageShop />,
					},
					{
						path: "/shopDetail/:id",
						exact: true,
						element: <ShopAgencyDetail />,
					},
				],
			},
			{
				path: "/login",
				element: <Login />,
			},
			{
				path: "/change-password",
				element: <ChangePassword />,
			},
		];

	const ThemeRoutes = [
		{ path: "*", exact: true, element: <Navigate to={"/"} /> },
		{
			path: "/",
			element: <FullLayout />,
			children: [
				{
					path: "/",
					element: <Navigate to="withdraw" />,
				},
				{ path: "/createshop", exact: true, element: <CreateShop /> },
				{ path: "/withdraw", exact: true, element: <Withdraw /> },
				{ path: "/dashboard", exact: true, element: <Dashboard /> },
				{
					path: "/checkoutHistory",
					exact: true,
					element: <Checkout />,
				},
				{ path: "/manageshop", exact: true, element: <ManageShop /> },
				{
					path: "/listaccountant",
					exact: true,
					element: <ListAccountant />,
				},
				{
					path: "/accountantDetail/:id",
					exact: true,
					element: <AccountantDetail />,
				},
				{
					path: "/shopDetail/:id",
					exact: true,
					element: <ShopDetail />,
				},
			],
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/change-password",
			element: <ChangePassword />,
		},
	];

	return ThemeRoutes;
}

export default {
	getRoutes: getRoutes,
};
