import axios from "axios";
import config from "./config";

var md5 = require("md5");

async function getAll(fromTime, toTime, pageIndex, pageSize, searchText) {
	try {
		let url =
			"/order/getAll?pageSize=" + pageSize + "&pageIndex=" + pageIndex;
		if (fromTime) {
			url = url + "&from=" + fromTime;
		}
		if (toTime) {
			url = url + "&to=" + toTime;
		}
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getDetail(order_id) {
	try {
		let url = "/order/getDetail?order_id=" + order_id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function create(body) {
	try {
		let url = "/order/create";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function update(body) {
	try {
		let url = "/order/update";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

export default {
	getAll: getAll,
	create: create,
	update: update,
	getDetail: getDetail,
};
