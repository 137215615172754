import axios from "axios";
import config from "./config";

var md5 = require("md5");

async function getAll(business_id, pageIndex, pageSize) {
	try {
		let url = "/customer/getAll?business_id=" + business_id;
		if (pageIndex) {
			url = url + "&pageIndex=" + pageIndex;
		}
		if (pageSize) {
			url = url + "&pageSize=" + pageSize;
		}
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getDetail(phone) {
	try {
		let url = "/customer/getDetail";
		url = url + "?phone=" + phone;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function create(body) {
	try {
		let url = "/customer/create";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function update(body) {
	try {
		let url = "/customer/update";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getAllCheckin() {
	try {
		let url = "/checkin/getAll";
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getDetailCheckin(code) {
	try {
		let url = "/checkin/getDetail";
		url = url + "?code=" + code;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getDetailCheckinByVoucher(voucher) {
	try {
		let url = "/checkin/getDetail";
		url = url + "?voucher=" + voucher;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateCheckin(body) {
	try {
		let url = "/checkin/update";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateValidVoucher(body) {
	try {
		let url = "/checkin/updateValidVoucher";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createCheckin(body) {
	try {
		let url = "/checkin/create";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function createLoyalty(body) {
	try {
		let url = "/business/checkin/createLoyalty";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function updateLoyalty(body) {
	try {
		let url = "/business/checkin/updateLoyalty";
		let response = await axios.post(url, body);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getCustomerStatistic(phone, business_id) {
	try {
		let url = "/checkin/getCustomerStatistic?business_id=" + business_id;
		url = url + "&phone=" + phone;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function getBusinessName(business_id) {
	try {
		let url = "/checkin/getBusinessName?business_id=" + business_id;
		let response = await axios.get(url);
		return response.data;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

export default {
	getAll: getAll,
	create: create,
	update: update,
	getDetail: getDetail,
	createCheckin: createCheckin,
	updateCheckin: updateCheckin,
	getAllCheckin: getAllCheckin,
	getDetailCheckin: getDetailCheckin,
	getDetailCheckinByVoucher: getDetailCheckinByVoucher,
	updateValidVoucher: updateValidVoucher,
	createLoyalty: createLoyalty,
	updateLoyalty: updateLoyalty,
	getCustomerStatistic: getCustomerStatistic,
	getBusinessName: getBusinessName,
};
