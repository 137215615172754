import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import config from "../services/config";

function getBusinessInfo(key) {
	let business = window.localStorage.getItem("user");
	business = JSON.parse(business);
	if (!business) return null;
	return business[key];
}

function getFullBusinessInfo(key) {
	let business = window.localStorage.getItem("user");
	business = JSON.parse(business);
	if (!business) return null;
	return business.business[key];
}

function getUserInfo(key) {
	let user = window.localStorage.getItem("user");
	user = JSON.parse(user);
	if (!user) return null;
	return user[key];
}

function getUserRole() {
	let business = window.localStorage.getItem("user");
	business = JSON.parse(business);

	return business["role"];
}

function getImage(url) {
	if (!url) return require("../assets/images/no-image.png");
	else return config.baseUrl + "/" + url;
}

function getFile(url) {
	if (!url) return require("../assets/images/no-image.png");
	else return config.baseUrl + "/" + url;
}

function isVisible(menu, business) {
	if (!menu.code) return true;
	if (!business.modules || business?.modules?.length < 5) return false;
	try {
		let modules = JSON.parse(business.modules);
		if (modules.includes(menu.code)) return true;
	} catch (error) {}

	return false;
}

function isReception() {
	if (window.location.toString().includes("panda")) return false;
	return true;
}

function getAppLogo() {
	if (window.location.toString().includes("a2btech"))
		return require("../assets/images/A2B.png");
	if (window.location.toString().includes("panda"))
		return require("../assets/images/panda_logo.png");
	if (window.location.toString().includes("brandname"))
		return require("../assets/images/brandname.jpg");
	return require("../assets/images/reception_logo.png");
}

function handleUKPhoneNumber(phoneNumber) {
	if (!phoneNumber) return "";

	let phone = phoneNumber.replaceAll(" ", "");
	phone = phone.replaceAll(".", "");
	if (phone[0] == "0") {
		return phone;
	} else if (phone.length == 10) {
		phone = "0" + phone;
	} else if (phone[0] == "4" && phone[1] == "4") {
		phone = "0" + phone.substring(2);
	} else if (phone[0] == "+" && phone[1] == "4" && phone[2] == "4") {
		phone = "0" + phone.substring(3);
	}

	return phone;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function getNearestYear(items) {
	let dateNearestYear;
	for (var i = 0; i < items.length; i++) {
		let tempTime = moment().subtract(365, "day").startOf("day");
		if (moment(items[i].bookingDateTime) < tempTime) {
			if (i > 0) {
				return moment(items[i - 1].bookingDateTime);
				break;
			} else {
				return null;
			}
		} else if (items.length == 1) {
			return moment(items[i].bookingDateTime);
		}
	}

	if (items.length > 0) {
		return moment(items[items.length - 1].bookingDateTime);
	}

	return dateNearestYear;
}

function getNumberOfDayLast12MonthSale(items) {
	if (items.length == 0) return 0;
	let dateNearestYear = getNearestYear(items);
	return Math.abs(
		dateNearestYear.diff(moment(items[0].bookingDateTime), "day")
	);
}

function getIncomeStatisticRaw(items, from_time, to_time, categories, avg) {
	let total = 0.0;
	let avgMax = 365;

	let dateNearestYear = getNearestYear(items);
	if (dateNearestYear) {
		avgMax = Math.abs(
			dateNearestYear.diff(moment(items[0].bookingDateTime), "days")
		);
		if (avgMax == 0) avgMax = 1;
	} else {
		avgMax = 1;
	}

	items.forEach((item) => {
		let check = true;
		categories = ["4000", "4001"];
		if (categories?.length > 0) {
			if (!item.category || item.category.length == 0) {
				check = false;
			} else {
				let categoryItem = item.category?.split(" | ")[0];
				if (!categories.includes(categoryItem.toString())) {
					check = false;
				}
			}
		}

		if (check || (!item.category && item.transactionAmount.amount > 0)) {
			let time = moment(item.bookingDateTime);
			if (time >= from_time && time <= to_time) {
				total = total + parseFloat(item.transactionAmount.amount);
			}
		}
	});
	if (avg > 0 && total != 0) {
		total = (total / avgMax) * avg;
	}
	return total.toFixed(2);
}

function getTotalDataRaw(items, from_time, to_time, categories, getPositive) {
	let total = 0.0;

	items.forEach((item) => {
		let check = true;
		if (categories?.length > 0) {
			if (!item.category || item.category.length == 0) {
				check = false;
			} else {
				let categoryItem = item.category?.split(" | ")[0];
				if (!categories.includes(categoryItem.toString())) {
					check = false;
				}
			}
		}

		if (getPositive) {
			check =
				check ||
				(!item.category &&
					getPositive &&
					item.transactionAmount.amount > 0);
		}

		if (check) {
			let time = moment(item.bookingDateTime);
			if (time >= from_time && time <= to_time) {
				total = total + parseFloat(item.transactionAmount.amount);
			}
		}
	});

	return total.toFixed(2);
}

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "GBP",
});

function getDataPaging(
	items,
	pageIndex,
	pageSize,
	searchText,
	from_time,
	to_time,
	showUncategory,
	showNoAttachment,
	filterCategory
) {
	let result = [];

	// if (searchText?.length > 0) {
	// 	result = items;
	// } else {
	// 	result = items;
	// }

	Array.from(items)
		.filter((item) => {
			let time = moment(item.bookingDateTime);

			if (from_time?.toString().length != 0) {
				if (time < moment(from_time)) return false;
			}

			if (to_time?.toString().length != 0) {
				if (time > moment(to_time)) return false;
			}

			if (filterCategory?.length > 3) {
				if (item.category !== filterCategory) return false;
			}

			if (showUncategory) {
				if (item.category?.length > 0) return false;
			}

			if (showNoAttachment) {
				if (item.attachment?.length > 0) return false;
			}

			return true;
		})
		.map((item) => {
			result.push(item);
		});

	const begin = (pageIndex - 1) * pageSize;
	const end = begin + pageSize;

	return {
		data: result,
		page: result.slice(begin, end),
	};
}

function makeid(length) {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(
			Math.floor(Math.random() * charactersLength)
		);
	}
	return result;
}
export default {
	delay: delay,
	getBusinessInfo: getBusinessInfo,
	getImage: getImage,
	getUserRole: getUserRole,
	isVisible: isVisible,
	getUserInfo: getUserInfo,
	isReception: isReception,
	getAppLogo: getAppLogo,
	handleUKPhoneNumber: handleUKPhoneNumber,
	getFullBusinessInfo: getFullBusinessInfo,
	getIncomeStatistic: getIncomeStatisticRaw,
	getIncomeStatisticRaw: getIncomeStatisticRaw,
	getDataPaging: getDataPaging,
	formatter: formatter,
	makeid: makeid,
	getTotalDataRaw: getTotalDataRaw,
	getFile: getFile,
	getNearestYear: getNearestYear,
	getNumberOfDayLast12MonthSale: getNumberOfDayLast12MonthSale,
};
