import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Shop from "../views/Shop/index.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
/****End Layouts*****/
const Business = lazy(() => import("../views/business"));
const Dashboard = lazy(() => import("../views/business/dashboard"));
const Accountant = lazy(() => import("../views/business/accountant"));
const AccountantHome = lazy(() => import("../views/business/accountanthome"));
const AccountantTransaction = lazy(() =>
	import("../views/business/transactions")
);
const DailySale = lazy(() => import("../views/business/dailysale"));
const ProfitLoss = lazy(() => import("../views/business/profitloss"));

const Withdraw = lazy(() => import("../views/history/withdraw"));
const PaymentHistory = lazy(() => import("../views/history/payment"));

const Service = lazy(() => import("../views/services"));
const Employee = lazy(() => import("../views/employee"));
const Customer = lazy(() => import("../views/customer"));
const Checkin = lazy(() => import("../views/checkin"));
const Loyalty = lazy(() => import("../views/checkin/loyalty"));
const CustomerCheck = lazy(() => import("../views/checkin/customerCheck"));

const CustomerCheckin = lazy(() => import("../views/checkin/customerCheckin"));
const CheckinHistory = lazy(() => import("../views/checkin/history"));
const Review = lazy(() => import("../views/checkin/review"));
const TopUp = lazy(() => import("../views/topup"));
const Messages = lazy(() => import("../views/messages"));
const Order = lazy(() => import("../views/order"));
const OrderDetail = lazy(() => import("../views/order/detail"));
const Payment = lazy(() => import("../views/payment"));
const CheckOut = lazy(() => import("../views/checkout"));
const StripeCheckout = lazy(() => import("../views/checkout/stripecheckout"));
const Voucher = lazy(() => import("../views/voucher"));

const QRCode = lazy(() => import("../views/qrcode"));

const Task = lazy(() => import("../views/task"));

const Login = lazy(() => import("../views/Login"));
const Register = lazy(() => import("../views/Register"));

const ChangePassword = lazy(() => import("../views/ChangePassword"));
const Billing = lazy(() => import("../views/billing/billing"));

/*****Routes******/

function getRoutes(user) {
	let firstModule = "business_info";
	let modules = [];
	if (user?.business?.modules) {
		try {
			modules = JSON.parse(user?.business?.modules);
		} catch (error) {}
	}
	if (user?.business?.home_page) {
		firstModule = user.business.home_page;
		if (firstModule == "sale") {
			firstModule = "bankinghome";
		}
	}

	const ThemeRoutes = [
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/register",
			element: <Register />,
		},
		{
			path: "/change-password",
			element: <ChangePassword />,
		},
		modules.includes("shop") ? { path: "/shop", element: <Shop /> } : {},
		{ path: "/checkout", exact: true, element: <CheckOut /> },
		{ path: "/check", exact: true, element: <CustomerCheck /> },
		{ path: "/checkin", exact: true, element: <CustomerCheckin /> },
		{ path: "/qrcode", exact: true, element: <QRCode /> },
		{ path: "/review", exact: true, element: <Review /> },
		{ path: "*", exact: true, element: <Navigate to={"/"} /> },
		{
			path: "/",
			element: <FullLayout />,
			children: [
				{
					path: "/",
					element: <Navigate to={firstModule} />,
				},
				{ path: "/business_info", exact: true, element: <Business /> },
				!modules.includes("dashboard")
					? {}
					: {
							path: "/dashboard",
							exact: true,
							element: <Dashboard />,
					  },
				!modules.includes("topup")
					? {}
					: {
							path: "/topup",
							exact: true,
							element: <TopUp />,
					  },
				!modules.includes("messages")
					? {}
					: {
							path: "/messages",
							exact: true,
							element: <Messages />,
					  },
				!modules.includes("moneyout")
					? {}
					: { path: "/moneyout", exact: true, element: <Withdraw /> },
				!modules.includes("moneyin")
					? {}
					: {
							path: "/moneyin",
							exact: true,
							element: <PaymentHistory />,
					  },
				modules.includes("service")
					? { path: "/service", exact: true, element: <Service /> }
					: {},
				!modules.includes("employee")
					? {}
					: { path: "/employee", exact: true, element: <Employee /> },
				!modules.includes("customerlist")
					? {}
					: {
							path: "/customerlist",
							exact: true,
							element: <Customer />,
					  },
				!modules.includes("order")
					? {}
					: { path: "/order", exact: true, element: <Order /> },
				{ path: "/order/:id", exact: true, element: <OrderDetail /> },
				!modules.includes("banking")
					? {}
					: {
							path: "/banking",
							exact: true,
							element: <Accountant />,
					  },
				!modules.includes("sale")
					? {}
					: {
							path: "/bankinghome",
							exact: true,
							element: <AccountantHome />,
					  },
				!modules.includes("banking")
					? {}
					: {
							path: "/transactions",
							exact: true,
							element: <AccountantTransaction />,
					  },
				!modules.includes("accounting")
					? {}
					: {
							path: "/profitloss",
							exact: true,
							element: <ProfitLoss />,
					  },
				!modules.includes("dailysale")
					? {}
					: {
							path: "/dailysale",
							exact: true,
							element: <DailySale />,
					  },
				!modules.includes("buscheckin")
					? {}
					: {
							path: "/buscheckin",
							exact: true,
							element: <Checkin />,
					  },
				!modules.includes("buscheckin")
					? {}
					: {
							path: "/loyalty",
							exact: true,
							element: <Loyalty />,
					  },

				!modules.includes("checkinHistory")
					? {}
					: {
							path: "/checkinHistory",
							exact: true,
							element: <CheckinHistory />,
					  },
				!modules.includes("messages")
					? {}
					: {
							path: "/voucher",
							exact: true,
							element: <Voucher />,
					  },
				{
					path: "/stripecheckout",
					exact: true,
					element: <StripeCheckout />,
				},

				{
					path: "/task",
					exact: true,
					element: <Navigate to="/" replace={true} />,
				},
				{
					path: "/billing",
					exact: true,
					element: <Billing />,
				},
			],
		},
	];

	return ThemeRoutes;
}

export default {
	getRoutes: getRoutes,
};
