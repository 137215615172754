import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { baseTheme } from "./assets/global/Theme-variable";
import OwnerRoute from "./routes/Router";
import EmployeeRouter from "./routes/EmployeeRouter";
import AdminRouter from "./routes/AdminRouter";

import { useAuth } from "./hooks/useAuth";
import auth from "./services/auth";
import appHelper from "./helper/appHelper";

const App = () => {
	const { user } = useAuth();

	React.useEffect(() => {}, []);

	let favicon = document.getElementById("favicon");
	if (favicon) {
		if (window.location.toString().includes("a2btech")) {
			favicon.href = "https://i.ibb.co/G98gMQc/A2B.png";
		} else if (window.location.toString().includes("panda")) {
			favicon.href =
				"https://pandacity.co.uk/static/media/panda_logo.12bc77be2df5c6301777.png";
		} else if (window.location.toString().includes("brandname")) {
			favicon.href =
				"https://brandname.ltd/static/media/brandname.a77d5cc43e9a35bcb0d7.jpg";
		} else {
			favicon.href =
				"https://vietbeauty.app/static/media/reception_logo.54396e69a6e8b2324300.png";
		}
	}

	let currentRoute = OwnerRoute.getRoutes(user);
	if (user?.role == "employee") {
		currentRoute = EmployeeRouter;
	} else if (
		user?.role == "super" ||
		user?.role == "accountant" ||
		user?.role == "agency"
	) {
		currentRoute = AdminRouter.getRoutes(user);
	}

	let routing = useRoutes(currentRoute);
	const theme = baseTheme;

	if (user) {
		auth.setAuthorizationToken(user.token);
	}

	function getTitle() {
		if (window.location.toString().includes("a2btech")) return "A2BTech";
		if (window.location.toString().includes("panda"))
			return "Panda City App";
		if (window.location.toString().includes("brandname"))
			return "BRANDNAME SMS";
		return "Beauty & Fashion App";
	}

	function getDescription() {
		if (window.location.toString().includes("a2btech"))
			return "Build your future with technology";
		if (window.location.toString().includes("panda"))
			return "Build your future with technology";
		if (window.location.toString().includes("brandname"))
			return "Text Marketing with BRANNAME SMS";
		return "Beauty - Fashion - Nail Platform";
	}

	return (
		<ThemeProvider theme={theme}>
			<title>{getTitle()}</title>
			<meta
				id="meta-description"
				name="description"
				content={getDescription()}
			/>
			{routing}
		</ThemeProvider>
	);
};

export default App;
