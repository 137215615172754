import axios from "axios";
import config from "./config";

var md5 = require("md5");

async function login(user_name, password) {
	let body = {
		user_name: user_name,
		password: md5(password),
	};
	try {
		let response = await axios.post("/auth", body);
		let result = response.data;
		if (result?.data) {
			setAuthorizationToken(result?.data.token);
		}
		return result;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

async function register(user_name, password, business_name, phone, created_by) {
	let body = {
		user_name: user_name,
		password: md5(password),
		business_name: business_name,
		phone: phone,
		created_by: created_by,
	};
	try {
		let response = await axios.post("/auth/register", body);
		let result = response.data;
		if (result?.data) {
			setAuthorizationToken(result?.data.token);
		}
		return result;
	} catch (error) {
		error.response.data.status = error.response.status;
		return error.response.data;
	}
}

function setAuthorizationToken(token) {
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
}

export default {
	register: register,
	login: login,
	setAuthorizationToken: setAuthorizationToken,
};
